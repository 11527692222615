
$primary: #cc0000 !default;
$secondary: #cccccc !default;
$danger:        $primary !default;
$schwarz: #231f20;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1260px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
) !default;

$navbar-padding-y:                  0px !default;

$navbar-dark-color:                 #ffffff !default;
$navbar-dark-hover-color:           #ffffff !default;
$navbar-dark-active-color:          #ffffff !default;

@import "bootstrap";


// Allgemein
html {
        background: #fbfbfb url("/fileadmin/templates/ff-telgte/images/shadow-site.png") repeat-x 0 0;
}
body {
        background-color: transparent;
}

h1,h2,h3,h4,h5,h6 {margin-top:0px;}
h1, .h1 { font-size: 24px; }
h2, .h2 { font-size: 20px; }
h3, .h3 { font-size: 18px; }
h4, .h4 { font-size: 16px; }
h5, .h5 { font-size: 14px; }
h6, .h6 { font-size: 12px; }
h1 { border-bottom:1px $primary solid; }

#navbarScroll > ul > li > a {
  text-shadow: 1px 1px 1px black;
}

// Carousel
// --------------------
//.carousel-indicators {
//  bottom: none;
//  top: 15px;
//}
.carousel-control {
  font-size: 40px;
}

.carousel-caption {
//  position: absolute;
  left: 0;
  right: 0;
//  bottom: 0;
//  padding: 15px;
//  background: #333333;
  background: rgba(0, 0, 0, 0.75);
//  text-align: left;
}
.carousel-caption h4,
.carousel-caption p {
  color: #ffffff;
  line-height: 20px;
}
.carousel-caption h4 {
  margin: 0 0 5px;
}
.carousel-caption p {
  margin-bottom: 0;
}

.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 0px;
  list-style: none;
  background-color: #fff;
  border-radius: 0px;
}

news-single-imgcaption {
        background-color: #d1d3d4;
}

figcaption {
        background-color:#EBEBEB;
        font-style: italic;
}
.csc-border { border: 1px solid $schwarz;}

.card-body { padding: 9px;}
.card {margin-bottom:20px;}

#navbarScroll > ul > li.nav-item.active {
	background-color:  darken($primary, 15%);
}
.subnav-nav {
	/* font-size: 0.875rem; */
	font-size: 0.9rem;
	line-height: 1.25;
	color: rgba(0, 0, 0, 0.5);
	width: 100%;
	margin-bottom: 0;
	padding-left: 0;
	list-style: none
}

.subnav-nav .subnav-nav {
	margin-top: -1px;
	border-left: 1rem solid #f8f8f8
}

.subnav-nav .subnav-nav:before {
	position: absolute;
	content: '';
	width: 1px;
	left: 0;
	top: 0;
	height: 100%;
	background-color: #e6e6e6
}

.subnav-divider {
	height: 0.5rem
}

.subnav-item .subnav-divider {
	background-color: #f8f8f8;
	border-right: 1px solid #e6e6e6
}

.subnav-item {
	position: relative
}

.subnav-item+.subnav-item {
	margin-top: -1px
}

.subnav-link {
	position: relative;
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
	/* color: inherit; */
	color: #000000;
	border: 1px solid #e6e6e6;
	transition: all 0.15s ease-in-out
}

.active>.subnav-link {
	color: $primary !important;
	background-color: transparent
}

.subnav-link:hover,
.subnav-link:focus {
	z-index: 1;
	position: relative;
	text-decoration: none;
	color: rgba(0, 0, 0, 0.7);
	background-color: #f2f2f2;
	border-color: #d9d9d9
}

.subnav-link-icon svg,
.subnav-link-icon img {
	display: block;
	margin: 0
}

.subnav-link-icon+.subnav-link-text {
	margin-left: 0.5em
}
